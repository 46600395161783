export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyBYzP-y1MjYsNoOoCpLv5R-uAm4bGZMTB4",
    authDomain: "baush-c94bd.firebaseapp.com",
    databaseURL: "https://baush-c94bd.firebaseio.com",
    projectId: "baush-c94bd",
    storageBucket: "baush-c94bd.appspot.com",
    messagingSenderId: "312116862035"
  },
  questions: {
    etape_1: [
      {
        text: "Ressentez-vous une douleur oculaire",
        hidden: false,
        index: 1,
        answer: null
      },
      {
        text: "Votre vision est-elle perturbée ?",
        hidden: true,
        index: 2,
        answer: null
      },
      {
        text: "Présence de photophobie?",
        hidden: true,
        index: 3,
        answer: null
      },
      {
        text: "La vision est-elle trouble ?",
        hidden: true,
        index: 4,
        answer: null
      }
    ],
    etape_2: [
      {
        text: "Ressentez-vous une fatigue oculaire?",
        hidden: false,
        index: 1,
        answer: null
      },
      {
        text: "Ressentez-vous une sensation de grain de sable dans l’œil ?",
        hidden: true,
        index: 2,
        answer: null
      },
      {
        text: "Ressentez-vous des picotements ?",
        hidden: true,
        index: 3,
        answer: null
      }
    ],
    etape_3: [
      {
        text: "êtes-vous sensible lors des saisons polliniques ?",
        hidden: false,
        index: 1,
        answer: null
      },
      {
        text: "Souffrez-vous de rhinite allergique ?",
        hidden: true,
        index: 2,
        answer: null
      },
      {
        text: "Rougeur diffuse bilatérale",
        hidden: true,
        index: 3,
        answer: null
      },
      {
        text: "Ressentez-vous des démangeaisons?",
        hidden: true,
        index: 4,
        answer: null
      },
      {
        text: "Ressentez-vous des larmoiements?",
        hidden: true,
        index: 5,
        answer: null
      }
    ],
    etape_4: [
      {
        text: "Présence de rougeur diffuse bilatérale/unilatérale. Sécrétions transparentes séreuses",
        hidden: false,
        index: 1,
        answer: null
      },
      {
        text: "Y-a-t-il un œdème important ?",
        hidden: true,
        index: 2,
        answer: null
      },
      {
        text: "Avez-vous un rhume ou une angine ?",
        hidden: true,
        index: 3,
        answer: null
      },
      {
        text: "Présence de ganglions peri auriculaire ?",
        hidden: true,
        index: 4,
        answer: null
      }
    ],
    etape_5: [
      {
        text: "Présence de rougeurs uni / bilatérale ?",
        hidden: false,
        index: 1,
        answer: null
      },
      {
        text: "Présence de sécrétions purulentes,abondantes ?",
        hidden: true,
        index: 2,
        answer: null
      },
      {
        text: "Y-a-t-il du pus à l’angle interne de l’œil ?",
        hidden: true,
        index: 3,
        answer: null
      },
      {
        text: "Les cils sont-ils agglutinés ?",
        hidden: true,
        index: 4,
        answer: null
      },
      {
        text: "Les paupières sont-elles collées au réveil ?",
        hidden: true,
        index: 5,
        answer: null
      }
    ],
    etape_6: [
      {
        text: "Présence de rougeur uni / bilatérale ?",
        hidden: false,
        index: 1,
        answer: null
      },
      {
        text: "Présence des petits larmoiements réflexes ?",
        hidden: true,
        index: 2,
        answer: null
      },
      {
        text: "Ressentez-vous des picotements ?",
        hidden: true,
        index: 3,
        answer: null
      },
      {
        text: "Ressentez-vous une fatigue oculaire ?",
        hidden: true,
        index: 4,
        answer: null
      }
    ],
    osdi: [
      {
        question: "Avez-vous ressenti l'un des symptômes suivants durant la semaine passée ?",
        index: 1,
        subtotalCat: "A",
        activePossibility: 1,
        len: 5,
        first: 1,
        possibilities: {
          1: {
            index: 1,
            text: "Photosensibilité",
            responded: null,
            list: [
              {
                text: "Tout le temps",
                value: 4
              },
              {
                text: "La plupart du temps",
                value: 3
              },
              {
                text: "La moitié du temps",
                value: 2
              },
              {
                text: "Quelques fois",
                value: 1
              },
              {
                text: "À aucun moment",
                value: 0
              },
            ]
          },
          2: {
            index: 2,
            text: "Sensation de corps étranger",
            responded: null,
            list: [
              {
                text: "Tout le temps",
                value: 4
              },
              {
                text: "La plupart du temps",
                value: 3
              },
              {
                text: "La moitié du temps",
                value: 2
              },
              {
                text: "Quelques fois",
                value: 1
              },
              {
                text: "À aucun moment",
                value: 0
              },
            ]
          },
          3: {
            index: 3,
            text: "Douleur ou irritation oculaire",
            responded: null,
            list: [
              {
                text: "Tout le temps",
                value: 4
              },
              {
                text: "La plupart du temps",
                value: 3
              },
              {
                text: "La moitié du temps",
                value: 2
              },
              {
                text: "Quelques fois",
                value: 1
              },
              {
                text: "À aucun moment",
                value: 0
              },
            ]
          },
          4: {
            index: 4,
            text: "Flou visuel",
            responded: null,
            list: [
              {
                text: "Tout le temps",
                value: 4
              },
              {
                text: "La plupart du temps",
                value: 3
              },
              {
                text: "La moitié du temps",
                value: 2
              },
              {
                text: "Quelques fois",
                value: 1
              },
              {
                text: "À aucun moment",
                value: 0
              },
            ]
          },
          5: {
            index: 5,
            text: "Baisse de l'acuité visuelle",
            responded: null,
            list: [
              {
                text: "Tout le temps",
                value: 4
              },
              {
                text: "La plupart du temps",
                value: 3
              },
              {
                text: "La moitié du temps",
                value: 2
              },
              {
                text: "Quelques fois",
                value: 1
              },
              {
                text: "À aucun moment",
                value: 0
              },
            ]
          }
        }
      },
      {
        question: "Ces symptômes oculaires ont-ils limité vos activités durant la semaine passée ?",
        index: 2,
        subtotalCat: "B",
        activePossibility: 6,
        len: 9,
        first: 6,
        possibilities: {
          6: {
            index: 6,
            text: "Lecture",
            responded: null,
            list: [
              {
                text: "Tout le temps",
                value: 4
              },
              {
                text: "La plupart du temps",
                value: 3
              },
              {
                text: "La moitié du temps",
                value: 2
              },
              {
                text: "Quelques fois",
                value: 1
              },
              {
                text: "À aucun moment",
                value: 0
              },
            ]
          },
          7: {
            index: 7,
            text: "Conduire nocturne",
            responded: null,
            list: [
              {
                text: "Tout le temps",
                value: 4
              },
              {
                text: "La plupart du temps",
                value: 3
              },
              {
                text: "La moitié du temps",
                value: 2
              },
              {
                text: "Quelques fois",
                value: 1
              },
              {
                text: "À aucun moment",
                value: 0
              },
            ]
          },
          8: {
            index: 8,
            text: "Travail sur écran",
            responded: null,
            list: [
              {
                text: "Tout le temps",
                value: 4
              },
              {
                text: "La plupart du temps",
                value: 3
              },
              {
                text: "La moitié du temps",
                value: 2
              },
              {
                text: "Quelques fois",
                value: 1
              },
              {
                text: "À aucun moment",
                value: 0
              },
            ]
          },
          9: {
            index: 9,
            text: "Télévision",
            responded: null,
            list: [
              {
                text: "Tout le temps",
                value: 4
              },
              {
                text: "La plupart du temps",
                value: 3
              },
              {
                text: "La moitié du temps",
                value: 2
              },
              {
                text: "Quelques fois",
                value: 1
              },
              {
                text: "À aucun moment",
                value: 0
              },
            ]
          },
        }
      },
      {
        question: "Avez-vous ressenti un inconfort oculaire durant la semaine passée ?",
        index: 3,
        subtotalCat: "C",
        activePossibility: 10,
        len: 12,
        first: 10,
        possibilities: {
          10: {
            index: 10,
            text: "Vent",
            responded: null,
            list: [
              {
                text: "Tout le temps",
                value: 4
              },
              {
                text: "La plupart du temps",
                value: 3
              },
              {
                text: "La moitié du temps",
                value: 2
              },
              {
                text: "Quelques fois",
                value: 1
              },
              {
                text: "À aucun moment",
                value: 0
              },
            ]
          },
          11: {
            index: 11,
            text: "Endroits ou climats très secs",
            responded: null,
            list: [
              {
                text: "Tout le temps",
                value: 4
              },
              {
                text: "La plupart du temps",
                value: 3
              },
              {
                text: "La moitié du temps",
                value: 2
              },
              {
                text: "Quelques fois",
                value: 1
              },
              {
                text: "À aucun moment",
                value: 0
              },
            ]
          },
          12: {
            index: 12,
            text: "Air conditionné",
            responded: null,
            list: [
              {
                text: "Tout le temps",
                value: 4
              },
              {
                text: "La plupart du temps",
                value: 3
              },
              {
                text: "La moitié du temps",
                value: 2
              },
              {
                text: "Quelques fois",
                value: 1
              },
              {
                text: "À aucun moment",
                value: 0
              },
            ]
          }
        }
      }
    ]
  },
  scoreGrid: [
    {
      e: 12, breakpoint: 52.1
    },
    {
      e: 11, breakpoint: 56.8
    },
    {
      e: 10, breakpoint: 62.5
    },
    {
      e: 9, breakpoint: 69.4
    },
    {
      e: 8, breakpoint: 62.5
    },
    {
      e: 7, breakpoint: 53.6
    },
    {
      e: 6, breakpoint: 62.5
    },
    {
      e: 5, breakpoint: 75
    },
    {
      e: 4, breakpoint: 62.5
    },
    {
      e: 3, breakpoint: 41.7
    },
    {
      e: 2, breakpoint: 62.5
    }
  ]
};
