import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from './guards/AuthGuardService';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: './home/home.module#HomePageModule'
  },
  {
    path: 'list',
    loadChildren: './list/list.module#ListPageModule'
  },
  {
    path: '',
    loadChildren: './pages/login/login.module#LoginPageModule',
    canActivate: [AuthGuardService]
  },
  {
    path: 'doctors',
    loadChildren: 'app/pages/doctors/doctors.module#DoctorsPageModule',
    canActivate: [AuthGuardService]
  },
  { path: 'etape1', loadChildren: 'app/pages/etapes/etape1/etape1.module#Etape1PageModule' },
  { path: 'speciaiste', loadChildren: './pages/etapes/speciaiste/speciaiste.module#SpeciaistePageModule' },
  { path: 'etape2', loadChildren: './pages/etapes/etape2/etape2.module#Etape2PageModule' },
  { path: 'etape3', loadChildren: './pages/etapes/etape3/etape3.module#Etape3PageModule' },
  { path: 'etape4', loadChildren: './pages/etapes/etape4/etape4.module#Etape4PageModule' },
  { path: 'etape5', loadChildren: './pages/etapes/etape5/etape5.module#Etape5PageModule' },
  { path: 'etape6', loadChildren: './pages/etapes/etape6/etape6.module#Etape6PageModule' },
  { path: 'artelac', loadChildren: './pages/etapes/artelac/artelac.module#ArtelacPageModule' },
  { path: 'levophta', loadChildren: './pages/etapes/levophta/levophta.module#LevophtaPageModule' },
  { path: 'desomedine', loadChildren: './pages/etapes/desomedine/desomedine.module#DesomedinePageModule' },
  { path: 'osdi', loadChildren: './pages/etapes/osdi/osdi.module#OsdiPageModule' },
  { path: 'resultat/:sc/:e', loadChildren: './pages/resultat/resultat.module#ResultatPageModule' },
  { path: 'liposic', loadChildren: './pages/etapes/liposic/liposic.module#LiposicPageModule' },
  { path: 'objectif', loadChildren: './pages/objectif/objectif.module#ObjectifPageModule' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
