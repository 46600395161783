import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { Storage } from '@ionic/storage';

@Injectable()
export class AuthGuardService implements CanActivate {

    constructor(private router: Router, private storage:Storage) {

    }

    canActivate(route: ActivatedRouteSnapshot): boolean {

        this.storage.get('registered').then((val) => {
            if(val == true){
                return false
            }
        });
        return true;
        

    }

}